import store from "@/state/store";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/content/scripts",
    name: "scripts",
    props: { currentTab: "scripts" },
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/scripts-page.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["auth/hasFeature"]("realtalk")) {
        return next("/realtalk");
      }
      next();
    },
  },
  {
    path: "/content/scripts-custom",
    name: "custom scripts",
    props: { currentTab: "custom_scripts" },
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/scripts-page.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["auth/hasFeature"]("realtalk")) {
        return next("/realtalk");
      }
      next();
    },
  },
  {
    path: "/content/scripts-my-recordings",
    name: "My Recordings",
    props: { currentTab: "recordings" },
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/scripts-page.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["auth/hasFeature"]("realtalk")) {
        return next("/realtalk");
      }
      next();
    },
  },
  {
    path: "/content/:contentType(scripts)/:slug",
    name: "Script Preview",
    props: true,
    meta: { authRequired: true, featureRequired: "realtalk" },
    component: () =>
      import("@/router/views/personalizedContent/script-detail.vue"),
  },
  {
    path: "/content/scripts/recordings/:recordingID",
    name: "RealTalk Recording",
    props: true,
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/scripts-recording.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["auth/hasFeature"]("realtalk")) {
        return next("/realtalk");
      }
      next();
    },
  },
  {
    path: "/scriptbuilder/:script?",
    name: "ScriptBuilder",
    props: true,
    meta: { authRequired: true, featureRequired: "realtalk" },
    component: () =>
      import("@/router/views/personalizedContent/script-builder.vue"),
  },
  {
    path: "/scriptbuilder/:slug/recordings",
    name: "Script Builder Recordings",
    props: true,
    meta: { authRequired: true, featureRequired: "realtalk" },
    component: () =>
      import("@/router/views/personalizedContent/recordings-page.vue"),
  },
  {
    path: "/scriptbuilder/:slug/recordings/:recordingID",
    name: "Script Builder Recording",
    props: true,
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/personalizedContent/scripts-recording.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["auth/hasFeature"]("realtalk")) {
        return next("/realtalk");
      }
      next();
    },
  },
];

export default routes;
