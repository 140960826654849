import { igAccessScopes } from "./../../services/socialmediaservice/helpers";
import { saveToLocalStorage } from "@/composables/helperUtils";
import { autopostService } from "@/services/internal-api/autopost.service";
import {
  AuthState,
  AuthService,
  AuthUser,
  AuthUserService,
  UserState,
} from "@/types";
import { Commit, Dispatch } from "vuex";
import * as Sentry from "@sentry/browser";
import TrackingService, {
  KcmTracker,
} from "@/services/trackingservice/tracking.service";
import { helpers } from "@/services/helpers";
import cacheService from "@/services/cache/cache.service";

/**
 * If the user is null, then set the user to null. Otherwise, set the user to the user
 * @param {AuthUser | null} user - AuthUser | null
 */
const identifyForSentry = (user: AuthUser | Partial<AuthUser>): void => {
  if (user.id) {
    Sentry.setUser({
      email: user.email,
      id: user.id,
      ip_address: "{{auto}}",
    });
  } else {
    Sentry.setUser(null);
  }
};

/**
 * If the page is refreshed and we have a logged in user, we set an event listener for when convertflow
 * fires their "cfReady" event. Once that happens we'll identify the user with Convertflow and then
 * remove the event listener so we don't end up with a ton of event listeners (idk if I really need to
 * worry about that but whatever)
 * @param event
 */
const identifyForConvertflow = (): void => {
  window.convertflow.identify({
    email: state.user.email,
    override: true,
  });
  window.removeEventListener("cfReady", identifyForConvertflow);
};

const getDefaultState = (): UserState => {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const user: AuthUser | Partial<AuthUser> = JSON.parse(
    localStorage.getItem("auth.user") || "{}"
  );
  return {
    user: user,
    token: JSON.parse(localStorage.getItem("auth.token") || "null"),
    last_sync: JSON.parse(localStorage.getItem("auth.last_sync") || "0"),
    forceRedirect: JSON.parse(
      localStorage.getItem("auth.force_redirect") || "null"
    ),
    admin: JSON.parse(localStorage.getItem("auth.admin") || "null"),
    teamOwner: JSON.parse(localStorage.getItem("auth.teamOwner") || "null"),
  };
};

const getEmptyState = (impersonate: boolean): UserState => {
  return {
    user: {},
    token: null,
    last_sync: 0,
    forceRedirect: null,
    admin: impersonate
      ? JSON.parse(localStorage.getItem("auth.admin") || "null")
      : null,
    teamOwner: impersonate
      ? JSON.parse(localStorage.getItem("auth.teamOwner") || "null")
      : null,
  };
};

export const state = getDefaultState();

export const getters = {
  // Whether the user is currently logged in.
  loggedIn(state: UserState): boolean {
    return state.user && state.token !== null;
  },
  authUser(state: UserState): AuthUser {
    return state.user;
  },
  authToken(state: UserState): string | null {
    return state.token;
  },
  authUserId(state: UserState): string {
    return state.user.id ?? "0";
  },
  authFirstName(state: UserState): string {
    return state.user.first_name ?? "";
  },
  authLastName(state: UserState): string {
    return state.user.last_name ?? "";
  },
  authEmail(state: UserState): string {
    return state.user.email ?? "";
  },
  authPhone(state: UserState): string {
    return state.user.phone ?? "";
  },
  authStatus(state: UserState): string {
    return state.user.status ?? "";
  },
  authType(state: UserState): string {
    return state.user.type ?? "";
  },
  authParent(state: UserState): AuthUser | null {
    const parents = state.user.parents;
    if (parents && parents.length > 0) {
      return parents[0].parent;
    }

    return null;
  },
  authParentId(state: UserState): string {
    const parents = state.user.parents;
    if (parents && parents.length > 0) {
      return parents[0].parent.id ?? "";
    }

    return "";
  },
  authNumberOfChildren(state: UserState): number {
    const children = state.user.children;
    if (children && children.length > 0) {
      return children.length;
    }

    return 0;
  },
  authAccessLevel(state: UserState): number {
    return state.user.access_levels
      ? state.user.access_levels[0].access_level
      : 1;
  },
  authIsCrew(state: UserState): boolean {
    return state.user.access_levels
      ? state.user.access_levels[0].access_level >= 2
      : false;
  },
  authAdmin(state: UserState): AuthState | null {
    return state.admin;
  },
  authTeamOwner(state: UserState): AuthState | null {
    return state.teamOwner;
  },
  forceRedirect(state: UserState): string {
    return state.forceRedirect ? state.forceRedirect : "";
  },
  getTopLevelFeatureIds(state: UserState): string[] {
    if (!state.user.features) {
      return [];
    }

    return Object.values(state.user.features).map((feature) => feature.id);
  },
  getFeaturesSlugs(state: UserState): string[] {
    if (!state.user.features) {
      return [];
    }

    let featureSlugs = state.user.features.map((feature) => feature.slug);

    const featuresWithChildren = state.user.features.filter(
      (feature) => feature.includes !== null
    );

    const childFeatureSlugs = [].concat(
      ...(featuresWithChildren.map((feature) =>
        feature.includes.map((child) => child.slug)
      ) as [])
    );

    if (childFeatureSlugs.length > 0) {
      featureSlugs = [...new Set([...featureSlugs, ...childFeatureSlugs])];
    }
    return featureSlugs;
  },
  getExpiredFeaturesSlugs(state: UserState): string[] {
    if (!state.user.expired_features) {
      return [];
    }
    return state.user.expired_features.map((feature) => feature.slug);
  },
  getPlanSlug(state: UserState): string {
    if (!state.user.features) {
      return "";
    }

    const featureSlugs = Object.values(state.user.features)
      .filter((feature) => {
        return feature.is_plan;
      })
      .map((feature) => feature.slug);

    if (featureSlugs.length < 0) {
      return "";
    }

    return featureSlugs[0];
  },
  getServiceByField:
    (state: UserState) =>
    (
      media: string,
      field: keyof AuthUserService
    ): number | AuthService | undefined | string | null => {
      if (!state.user.services) {
        return null;
      }

      const mediaService = Object.values(state.user.services).filter(
        (service) => {
          return service.service.slug === media;
        }
      );

      if (mediaService.length <= 0) {
        return null;
      }

      return mediaService[0][field];
    },
  getSocialToken:
    (state: UserState) =>
    (media: string): AuthUserService | null => {
      if (!state.user.services) {
        return { service: { slug: "" } };
      }
      let ogMedia = "";
      // we need to check the facebook token for instagram
      if (media === "instagram") {
        ogMedia = media;
        media = "facebook";
      }

      const services = state.user.services.filter((service) => {
        return service.service.slug === media;
      });

      if (services.length <= 0) {
        return null;
      }

      // check the scopes in the facebook token for instagram permissions
      if (ogMedia === "instagram") {
        if (services[0].scope) {
          const scopes = services[0].scope.split(",");
          if (
            igAccessScopes.some((val) => {
              return scopes.indexOf(val) !== -1;
            })
          ) {
            return services[0];
          } else {
            return null;
          }
        } else {
          return null;
        }
      }
      return services[0];
    },
  isStandardMember(state: UserState): boolean {
    return !state.user.type;
  },
  isAllowedReferrals(
    state: UserState,
    getters: { isStandardMember: boolean; authParent: AuthUser | null }
  ): boolean {
    return getters.isStandardMember && getters.authParent === null;
  },
  isTeamOwner(state: UserState): boolean {
    if (!state.user.team) return false;
    return (
      state.user.team.owner_id === state.user.id &&
      state.user.type === "Team Owner"
    );
  },
  isEnterpriseOwner(state: UserState): boolean {
    if (!state.user.team) return false;
    return (
      state.user.team.owner_id === state.user.id &&
      state.user.type === "Enterprise Owner"
    );
  },
  isTeamOrEnterpriseOwner(
    state: UserState,
    getters: { isTeamOwner: boolean; isEnterpriseOwner: boolean }
  ): boolean {
    return getters.isTeamOwner || getters.isEnterpriseOwner;
  },
  isAnyTeamOwner(state: UserState): boolean {
    return [
      "Coach Leader",
      "Enterprise Owner",
      "Team Owner",
      "Free Team Owner",
      "Company Owner",
      "Branch Owner",
    ].includes(state.user.type ?? "");
  },
  isTeamAdmin(state: UserState): boolean {
    const parents = state.user.parents as AuthUser[];
    if (parents && parents.length > 0) return parents[0].is_admin ?? false;
    return false;
  },
  async linkedInTokenExpiresSoon(state: UserState): Promise<boolean> {
    if (!state.user.services) {
      return false;
    }

    const linkedInService = Object.values(state.user.services).filter(
      (service) => {
        return service.service.slug === "linkedin";
      }
    );

    if (linkedInService.length === 0) {
      return false;
    }

    const now = new Date();
    const soon = new Date();
    soon.setDate(now.getDate() + 7);

    const timestamp = Math.round(soon.getTime() / 1000);
    const hasLinkedInJob = await autopostService.hasJobWithSocial(4);
    if (
      linkedInService[0].expires &&
      parseInt(linkedInService[0].expires) <= timestamp &&
      hasLinkedInJob
    ) {
      return true;
    }

    return false;
  },
  async linkedInTokenExpired(state: UserState): Promise<boolean> {
    if (!state.user.services) {
      return false;
    }

    const linkedInService = Object.values(state.user.services).filter(
      (service) => {
        return service.service.slug === "linkedin";
      }
    );
    const hasLinkedInJob = await autopostService.hasJobWithSocial(4);

    if (linkedInService.length === 0) {
      return hasLinkedInJob;
    }

    const timestamp = Math.round(new Date().getTime() / 1000);
    if (
      linkedInService[0].expires &&
      parseInt(linkedInService[0].expires) <= timestamp &&
      hasLinkedInJob
    ) {
      return true;
    }

    return false;
  },
  hasFeature:
    (state: UserState, getters: { getFeaturesSlugs: string[] }) =>
    (feature: string, acceptBeta = false): boolean => {
      const { result: strippedFeature, wasStripped: reverseLookup } =
        helpers.stripCharacterIfExists(feature, "!");

      const featureSlugs = [strippedFeature];
      /**
       * If acceptBeta is true, add the beta version of each tag that was passed into this function
       */
      if (acceptBeta) {
        featureSlugs.push(strippedFeature + "-beta");
      }

      if (reverseLookup) {
        return !featureSlugs.some((tag) =>
          getters.getFeaturesSlugs.includes(tag)
        );
      }
      return featureSlugs.some((tag) => getters.getFeaturesSlugs.includes(tag));
    },
  hasExpiredFeature:
    (state: UserState, getters: { getExpiredFeaturesSlugs: string[] }) =>
    (feature: string): boolean => {
      const expiredFeatureSlugs = [feature];

      return expiredFeatureSlugs.some((tag) =>
        getters.getExpiredFeaturesSlugs.includes(tag)
      );
    },
  lastSync(state: UserState): number {
    return state.last_sync;
  },
};

export const actions = {
  /**
   * This is where we should run anything that needs to identify the logged in
   * user i.e. CZ, Convertflow, Elevio, etc.
   * @param
   */
  identify(): void {
    if (!state.user) return;
    if (!state.user.email) return;

    window.addEventListener("cfReady", identifyForConvertflow);
    identifyForSentry(state.user);

    TrackingService.listTrackers().forEach((tracker: KcmTracker) => {
      tracker.identify?.(state.user);
    });
  },

  /**
   * Deprovision all identities on logout
   */
  clearIdentity(): void {
    window.convertflow?.identify({
      email: null,
    });
    identifyForSentry({});

    TrackingService.listTrackers().forEach((tracker: KcmTracker) => {
      tracker.identify?.({});
    });
  },

  // Logs in the user.
  login(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    user: UserState
  ): void {
    cacheService.clearAll();

    commit("SET_CURRENT_USER", user.user);
    commit("SET_CURRENT_TOKEN", user.token);
    commit("SET_LAST_SYNC");
    dispatch("identify", user.user);
  },
  // Logout the user
  logout({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }): void {
    commit("SET_EMPTY_STATE", false);
    dispatch("clearIdentity");
  },
  setCurrentUser({ commit }: { commit: Commit }, user: UserState): void {
    commit("SET_CURRENT_USER", user);
    commit("SET_LAST_SYNC");
  },
  setForceRedirect({ commit }: { commit: Commit }, value: string): void {
    commit("SET_FORCE_REDIRECT", value);
  },
  setAuthAdmin({ commit, state }: { commit: Commit; state: UserState }): void {
    if (state.admin === null) {
      commit("SET_AUTH_ADMIN", {
        user: state.user,
        token: state.token,
      });
    }
  },
  setTeamOwner({ commit, state }: { commit: Commit; state: UserState }): void {
    if (state.teamOwner === null) {
      commit("SET_TEAM_OWNER", {
        user: state.user,
        token: state.token,
      });
    }
  },
  swapAuthToActive({
    commit,
    state,
  }: {
    commit: Commit;
    state: UserState;
  }): void {
    if (state.admin) {
      commit("SET_EMPTY_STATE", true);
      commit("SET_CURRENT_USER", state.admin.user);
      commit("SET_CURRENT_TOKEN", state.admin.token);
      commit("SET_AUTH_ADMIN", null);
      commit("SET_TEAM_OWNER", null);
    }
  },
  swapTeamOwnerToActive({
    commit,
    state,
  }: {
    commit: Commit;
    state: UserState;
  }): void {
    if (state.teamOwner) {
      commit("SET_EMPTY_STATE", true);
      commit("SET_CURRENT_USER", state.teamOwner.user);
      commit("SET_CURRENT_TOKEN", state.teamOwner.token);
      commit("SET_TEAM_OWNER", null);
    }
  },

  setEmptyState({ commit }: { commit: Commit }, impersonate: boolean): void {
    commit("SET_EMPTY_STATE", impersonate);
  },
};

export const mutations = {
  SET_CURRENT_USER(state: UserState, newValue: AuthUser): void {
    if (newValue === null) {
      state.user = {};
    } else {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      delete (newValue as any).history;
      state.user = newValue;
    }
    saveToLocalStorage("auth.user", state.user);
  },
  SET_CURRENT_TOKEN(state: UserState, newValue: string): void {
    state.token = newValue;
    saveToLocalStorage("auth.token", newValue);
  },
  SET_LAST_SYNC(state: UserState): void {
    state.last_sync = parseInt(Date.now().toString());
    saveToLocalStorage("auth.last_sync", state.last_sync);
  },
  SET_FORCE_REDIRECT(state: UserState, newValue: string): void {
    state.forceRedirect = newValue;
    saveToLocalStorage("auth.force_redirect", newValue);
  },
  SET_AUTH_ADMIN(state: UserState, newValue: AuthState | null): void {
    state.admin = newValue;
    saveToLocalStorage("auth.admin", newValue);
  },
  SET_TEAM_OWNER(state: UserState, newValue: AuthState | null): void {
    state.teamOwner = newValue;
    saveToLocalStorage("auth.teamOwner", newValue);
  },
  SET_EMPTY_STATE(state: UserState, impersonate: boolean): void {
    const emptyState = getEmptyState(impersonate);
    Object.assign(state, emptyState);

    saveToLocalStorage("auth.user", emptyState.user);
    saveToLocalStorage("auth.token", emptyState.token);
    saveToLocalStorage("auth.last_sync", emptyState.last_sync);
    saveToLocalStorage("auth.force_redirect", emptyState.forceRedirect);
    saveToLocalStorage("auth.admin", emptyState.admin);
    saveToLocalStorage("auth.teamOwner", emptyState.teamOwner);
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
