import store from "@/state/store";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";

const routes: RouteConfig[] = [
  {
    path: "/realtalk",
    name: "RealTalk",
    meta: { authRequired: true },
    component: () => import("@/router/views/realtalk/realtalk.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (store.getters["auth/hasFeature"]("realtalk")) {
        return next("/content/scripts");
      }
      next();
    },
  },
  { path: "/realtalk-promo", redirect: "/realtalk" },
  // TODO: Add a before enter to make sure this page isn't loading without a script stored in the useRealtalk composable.
  // Or just go ahead and create a barebones record freecam mode?
  {
    path: "/realtalk/record",
    name: "RealTalk Recording Studio",
    meta: { authRequired: true, featureRequired: "realtalk" },
    component: () => import("@/router/views/realtalk/realtalk-recording.vue"),
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      if (!store.getters["realtalk/getScript"]) {
        return next("/content/scripts");
      }
      next();
    },
  },
  {
    path: "/realtalk/review",
    name: "RealTalk Recording Preview",
    props: true,
    meta: { authRequired: true, featureRequired: "realtalk" },
    component: () =>
      import("@/router/views/personalizedContent/scripts-recording.vue"),
  },
];

export default routes;
