import store from "@/state/store";
import { AuthUser, KcmContent } from "@/types";
import { Route } from "vue-router/types/router";
import { trackEvent as lambdaTrack } from "./tracking.lambda";
import router from "@/router";

let trackers: KcmTracker[] = [];

export interface KcmContentEvent {
  content?: KcmContent;
  contentType: string;
  method?: string;
  fileName?: string;
  description?: string;
  customization?: string;
  personalization?: string;
  emailFormat?: string;
  fullContent?: KcmContent;
  videoLength?: number;
  aspectRatio?: string;
  eventTarget?: string;
}

export interface KcmTracker {
  identify?(user: Partial<AuthUser>): void;
  updateUserAttributes?(data: Record<string, unknown>): void;
  name(data?: Record<string, never>): string;
  pageView?(data?: { to: Route }): void;
  login?(data: { method: string }): void;
  logout?(data?: Record<string, never>): void;
  viewedContent?(data: KcmContentEvent): void;
  sharedContent?(data: KcmContentEvent): void;
  scheduledContent?(data: KcmContentEvent): void;
  engagedContent?(data: KcmContentEvent): void;
  watchedContent?(data: KcmContentEvent): void;
  copiedContent?(data: KcmContentEvent): void;
  downloadedContent?(data: KcmContentEvent): void;
  signedUpForGSW?(data?: Record<string, never>): void;
  registerWebinar?(data: KcmContentEvent): void;
  uploadedIntroOutro?(data?: Record<string, never>): void;
  purchasingPower?(data?: Record<string, never>): void;
  autopostJobSetup?(data?: Record<string, never>): void;
  connectedSocial?(data: { media: string }): void;
  viewSTM?(data: KcmContentEvent): void;
  viewSpanish?(data: KcmContentEvent): void;
  cancel?(): void;
  save?(data: Record<string, never>): void;
  modifiedZips?(): void;
  createdNewScript?(data?: Record<string, never>): void;
  createdScriptFromScratch?(data?: Record<string, never>): void;
  createdScriptFromTemplate?(data?: Record<string, never>): void;
  uploadedGraphic?(data?: Record<string, never>): void;
  clickedScriptEditorTool?(data: KcmContentEvent): void;
  insertedLocalAIBlock?(data?: Record<string, never>): void;
  filteredContent?(data?: Record<string, never>): void;
  searched?(data: string): void;
  playedAudio?(data: KcmContentEvent): void;
  startTestDrive?(): void;
  upload?(data: string): void;
  selectedContent?(data: KcmContentEvent): void;
  create?(data: KcmContentEvent): void;
  trash?(data: KcmContentEvent): void;
  initiatePlanChange?(): void;
  customize?(data: KcmContentEvent): void;
  startCancel?(): void;
  acceptCancelSave?(data: { offerId: string }): void;
  acceptCancelDeflect?(data: { offerId: string }): void;
  closeCancel?(): void;
  declineCancelSave?(): void;
  uploadRecording?(): void;
  record?(): void;
  download?(): void;
}

export type Section =
  | "dashboard"
  | "shareable-content"
  | "market-updates"
  | "local"
  | "scripts"
  | "email-builder"
  | "resources"
  | "learning-center"
  | "(empty)";

const TrackingService = {
  /* Returning the array of trackers. */
  listTrackers(): KcmTracker[] {
    return trackers;
  },

  /* Adding a new tracker to the array of trackers. */
  registerTracker(tracker: KcmTracker): void {
    trackers.push(tracker);
  },

  /* Removing a tracker from the array of trackers. */
  deregisterTracker(name: string): void {
    trackers = trackers.filter((t) => {
      return t.name() !== name;
    });
  },

  /* Looping through the array of trackers and calling the method that was passed in. */
  // eslint-disable-next-line
  trackEvent(label: keyof KcmTracker, data?: any): void {
    //if we're currently impersonating, skip event tracking
    if (store.getters["authAdmin"]) return;

    // Add section metadata to all events before distributing it
    if (data === undefined) {
      data = {};
    }
    const clonedData = JSON.parse(JSON.stringify(data));

    /**
     * Adding a try catch here so that invalid event data won't
     * stop a script from executing
     */
    try {
      clonedData.section = this.getSection();
      clonedData.content_language = store.getters["settings/languageSetting"];

      /**
       * adjust to consistent content type naming conventions
       * will clean this up sometime soon when content types are fixed up... maybe
       */
      if (clonedData?.contentType) {
        clonedData.contentType = this.adjustContentType(clonedData.contentType);
      }
      if (clonedData?.content?.content_type) {
        clonedData.content.content_type = this.adjustContentType(
          clonedData.content.content_type
        );
      }

      // Track on the Lambda tracker, this is currently not registered... we'll clean
      // this all up soon
      lambdaTrack(label, clonedData);

      /**
       * Add some necessary data for the remaining tracked events...
       */
      const tier = store.getters["auth/getPlanSlug"];
      if (tier) clonedData.plan_tier = tier;

      // Distribute event to other trackers.
      // CZ gets most events custom. The others are getting only identify, updateUserAttributes, and pageViews
      trackers.forEach((tracker) => {
        tracker[label]?.(clonedData ?? {});
      });
    } catch (err) {
      console.error("Error processing event: ", err);
      return;
    }
  },

  updateUserAttributes(data: Record<string, unknown>): void {
    if (store.getters["authAdmin"]) return;

    trackers.forEach((tracker: KcmTracker) => {
      tracker.updateUserAttributes?.(data);
    });
  },

  adjustContentType(contentType: string): string {
    //make lower case
    let cType = contentType.toLowerCase();

    //remove extra descriptors from content type
    cType = cType.replace(/(-schedule|-single|-multi)/, "");

    // remove trailing s on specific content types
    if (["scripts", "videos", "social-graphics"].includes(cType)) {
      cType = cType.replace(/s$/, "");
    }

    return cType;
  },

  getSection(): Section {
    const currentRoutePath = router.currentRoute.path;
    const validSections: Section[] = [
      "dashboard",
      "shareable-content",
      "market-updates",
      "local",
      "scripts",
      "email-builder",
      "resources",
      "learning-center",
    ];

    return (
      validSections.find((section) => currentRoutePath.includes(section)) ||
      "(empty)"
    );
  },

  AorB(email: string): string {
    // Create a hash from the email
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      const char = email.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0; // Convert to 32bit integer
    }

    if (hash % 2 === 0) {
      return "a";
    } else {
      return "b";
    }
  },
};

export default TrackingService;
