import store from "@/state/store";
import {
  FacebookApiShareOptions,
  FacebookScheduledPost,
  KcmContent,
  KcmNetworkOptions,
} from "@/types";
import axios, { AxiosResponse } from "axios";
import { userService } from "../internal-api/auth.service";
import { contentService } from "../internal-api/content.service";
import { thirdPartyService } from "../internal-api/thirdparty.service";
import { helpers } from "@/services/helpers";
import { b64toBlob } from "@/composables/helperUtils";
import {
  facebookScopes,
  fbPostingScopes,
  fbAccessScopes,
  igAccessScopes,
} from "./helpers";
import {
  KcmSocialPageData,
  KcmSocialPromise,
  ServiceData,
} from "@/types/social-media.types";

declare interface ServiceResponse {
  data?: {
    response?: Response;
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  error?: any;
}

declare interface Response {
  __usage__: {
    app: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    page: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    ad_account: {
      call_count: number;
      total_time: number;
      total_cputime: number;
      type: string;
      estimated_time_to_regain_access: number;
    };
    ads_insights: {
      app_id_util_pct: number;
      acc_id_util_pct: number;
    };
    business_use_case: unknown;
  };
  access_token: string;
  expires_in: number;
  token_type: string;
}

const tokenErrors = {
  code: 190,
  subcodes: [
    463, //expired or invalid token
    460, //token invalid because user logged out of facebook or changed password on facebook
    458, //token invalid because app was unauthorized
  ],
};

// const emailIds = {
//   expiredToken: 95075929870,
// };

const appId = process.env.VUE_APP_FB_APP_ID;
const sdkVersion = "v20.0";

const scopes = facebookScopes.join(",");
const baseUrl = process.env.VUE_APP_SERVICES_API_ROOT
  ? process.env.VUE_APP_SERVICES_API_ROOT.replace(/\/$/, "")
  : "";
const HOST =
  baseUrl +
  "/facebook/v1/industries/" +
  (process.env.VUE_APP_INDUSTRY_ID ?? "1") +
  "/long_lived";

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
function facebookServicePost(data: unknown): Promise<AxiosResponse<any, any>> {
  return axios.post(HOST, data, { ...thirdPartyService.HEADERS() });
}

const connectPermissions = {
  scope: scopes,
  return_scopes: true,
};

const serviceData: ServiceData = {
  initiated: false,
  pages: [],
};

export const facebookService = {
  name: "facebook",
  scopes,
  login,
  init,
  connect,
  disconnect,
  checkAccess,
  checkForMissingScopes,
  checkStoreForMissingScopes,
  post,
  update,
  remove,
  getPages,
  getScheduledPosts,
  facebookServicePost,
  clearExpirationDateInCRM,
  serviceData,
};

function checkAccess(): boolean {
  const hasToken = store.getters["auth/getSocialToken"]("facebook");
  if (!hasToken) {
    return false;
  }
  return checkStoreForMissingScopes() === "";
}

function checkTokenErrors(response: ServiceResponse): void {
  if (response?.error?.code === tokenErrors.code) {
    if (tokenErrors.subcodes.includes(response.error.error_subcode || 0)) {
      //token was invalidated for one of the 4 main reasons. send update to hubspot and trigger email
      const email = store.getters["auth/authEmail"];

      saveExpirationDateInCRM(email);
      userService.removeServiceData(
        store.getters["auth/authUserId"],
        "facebook"
      );
    }
  }
}

function saveExpirationDateInCRM(email: string): void {
  const myDate = new Date();
  const formatted = myDate.toISOString().split("T")[0];

  thirdPartyService
    .post("/hubspot/v1/contact/" + email, {
      kcm_fb_check_date: formatted,
    })
    .catch((error) => {
      helpers.customSentryError(
        "Failed to save expiration date for FB in CRM",
        ".",
        {
          error: error,
        }
      );
    });
}

function clearExpirationDateInCRM(email: string): void {
  thirdPartyService
    .post("/hubspot/v1/contact/" + email, {
      kcm_fb_check_date: "",
    })
    .catch((error) => {
      helpers.customSentryError(
        "Failed to clear expiration date for FB in CRM",
        ".",
        {
          error: error,
        }
      );
    });
}

async function login(): Promise<KcmSocialPromise> {
  return new Promise((resolve): void => {
    window.FB.login(function (response: {
      error?: string;
      authResponse?: { userID: string };
    }): void {
      if (response.error) {
        resolve({ success: false, error: response.error });
      }
      if (response.authResponse && response.authResponse.userID) {
        resolve({ success: true, data: response.authResponse.userID });
      }
      resolve({
        success: false,
        error: "Facebook Sign In Failed - No Token returned from Facebook",
      });
    });
  });
}

async function init(): Promise<boolean> {
  serviceData.initiated = true;

  /**
   * recommended approach to initialize from Meta docs.
   *
   * basically this wrapper houses our initialize function
   * until the meta script gets loaded async.
   *
   * the caveat i've noticed is that firefox seems a bit slower
   * to load deferred scripts, whereas chrome performs faster, so
   * there are scenarios where FB and IG attempt to get initialized
   * before window.FB gets set by that script. this should resolve that.
   */
  window.fbAsyncInit = function (): void {
    try {
      window.FB.init({
        appId: appId,
        cookie: true,
        version: sdkVersion,
      });
    } catch {
      serviceData.initiated = false;
    }
  };

  /**
   * this will most likely be undefined
   * for firefox users, so just return true,
   * because it will automagically run the fbASyncInit
   * in the background when loaded, and we can just
   * control the initiated value in that try/catch block
   * if it does happen to fail.
   */
  if (window.FB === undefined) {
    return serviceData.initiated;
  }

  window.fbAsyncInit();

  return serviceData.initiated;
}

async function connect(): Promise<KcmSocialPromise> {
  return new Promise((resolve): void => {
    window.FB.login(async function (response: {
      error?: string;
      authResponse?: { userID: string; grantedScopes: string[] };
    }): Promise<void> {
      if (response.authResponse && response.authResponse.userID) {
        const authResponse = response.authResponse;

        const errorMessage = checkForMissingScopes(authResponse);

        if (errorMessage) {
          resolve({
            success: false,
            error: errorMessage,
          });
        }

        let serviceResponse: ServiceResponse = {};

        serviceResponse = (await facebookServicePost({
          access_token: window.FB.getAuthResponse()["accessToken"],
        })) as ServiceResponse;

        if (serviceResponse?.data?.response) {
          const email = store.getters["auth/authEmail"];
          clearExpirationDateInCRM(email);
          resolve({
            success: true,
            data: {
              token: serviceResponse.data.response.access_token,
              id: window.FB.getAuthResponse()["userID"],
              scope: response.authResponse.grantedScopes,
            },
          });
        } else if (serviceResponse.error) {
          resolve({
            success: false,
            error: "There was an error getting a long lived token.",
          });
        }
      }
      resolve({
        success: false,
        error: "User closed Facebook login or did not fully authorize access.",
      });
    },
    connectPermissions);
  });
}

async function disconnect(): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    window.FB.api("/me/permissions", "delete", {
      access_token: store.getters["auth/getServiceByField"](
        facebookService.name,
        "token"
      ),
    });
    resolve({ success: true });
  });
}

function checkForMissingScopes(
  authResponse: {
    userID: string;
    grantedScopes: string[];
  },
  checkIg?: boolean
): string {
  if (!authResponse.grantedScopes) {
    return "It looks like you haven't granted us permission to Facebook. You'll need to approve all permissions in order to share and schedule posts to your Facebook pages.";
  }

  const missingForPosting = fbPostingScopes.filter(function (p) {
    return !authResponse.grantedScopes.includes(p);
  });
  const missingForPages = fbAccessScopes.filter(function (p) {
    return !authResponse.grantedScopes.includes(p);
  });
  const errorMessage = [];

  if (missingForPosting.length > 0) {
    errorMessage.push("to post to your pages");
  }
  if (missingForPages.length > 0) {
    errorMessage.push("to access your pages");
  }

  if (checkIg) {
    const missingForIg = igAccessScopes.filter(function (p) {
      return !authResponse.grantedScopes.includes(p);
    });

    if (missingForIg.length > 0) {
      errorMessage.push("to post to Instagram");
    }
  }

  if (errorMessage.length <= 0) {
    return "";
  }

  return (
    "It looks like you haven't granted us permission " +
    errorMessage.join(",") +
    ". You'll need to approve all permissions in order to share and schedule posts to your Facebook pages."
  );
}

function checkStoreForMissingScopes(checkIg?: boolean): string {
  return checkForMissingScopes(
    {
      userID: store.getters["auth/authUserId"],
      grantedScopes: store.getters["auth/getServiceByField"](
        "facebook",
        "scope"
      ),
    },
    checkIg
  );
}

async function post(
  content: KcmContent,
  shareType: string,
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  // Use simple share for blog and videos-link sharing

  // NOTE: 3/18/2024 - After updating this to work with some of my new code,
  // I think we should explore just breaking this functionality out
  // to a separate method on all the social medias specifically for
  // simple sharing - no rush, updating this content type fixed it.
  if (
    ["blog", "videos-link"].includes(shareType) &&
    !networkOptions?.schedule_time
  ) {
    let contentUrl = store.getters["profile/profileSTMBlogLink"](
      content,
      store.getters["settings/contentLanguageSetting"]
    );

    if (shareType === "videos-link") {
      contentUrl = store.getters["profile/profileSTMLinkExtended"](
        "videos/" + content.slug,
        store.getters["settings/contentLanguageSetting"]
      );
    }
    simpleShare(contentUrl);
    return new Promise((resolve) =>
      resolve({ success: true, data: "Simple Share initiated" })
    );
    // Schedule a blog post via API
  } else {
    if (networkOptions === undefined || !networkOptions.id) {
      return new Promise((resolve) =>
        resolve({ success: false, error: "Missing network data" })
      );
    }

    let endpoint = "feed";

    switch (shareType) {
      case "blog":
        networkOptions.link = store.getters["profile/profileSTMBlogLink"](
          content,
          store.getters["settings/contentLanguageSetting"]
        );
        break;
      case "video":
      case "realtalk_video":
        ({ endpoint, networkOptions } = await prepVideoToFacebook(
          content,
          shareType,
          networkOptions
        ));
        break;
      case "social-graphics-single":
      case "social-graphics-multi":
      case "infographics":
      case "slide":
      case "mmr":
      case "local":
        networkOptions = await prepGraphicToFacebook(content, networkOptions);
        break;
      default:
        return new Promise((resolve) =>
          resolve({
            success: false,
            error: "Content type not set up to share via facebook",
          })
        );
    }

    const apiResp = await apiShare(networkOptions, endpoint);
    return apiResp;
  }
}

async function prepVideoToFacebook(
  content: KcmContent,
  shareType: string,
  networkOptions: KcmNetworkOptions
): Promise<{
  endpoint: string;
  networkOptions: KcmNetworkOptions;
}> {
  // need to get personalized url for video, otherwise contents is good
  if (shareType === "video") {
    networkOptions.file_url = contentService.personalizedVideoUrl(
      content.contents
    );
  } else {
    networkOptions.file_url = content.contents;
  }

  networkOptions.title = content.title;
  networkOptions.name = content.title;
  // set message to description because videos are different
  networkOptions.description = networkOptions.message;

  /**
   * If a thumbnail is defined, fetch it and turn it into a blob
   * for uploading to facebook
   */
  if (content.featured_image) {
    networkOptions.thumb = await fetch(
      content.featured_image + "?kcmcache=1"
    ).then((r) => r.blob());
  }

  return { endpoint: "videos", networkOptions: networkOptions };
}

async function prepGraphicToFacebook(
  content: KcmContent,
  networkOptions: KcmNetworkOptions
): Promise<KcmNetworkOptions> {
  const images = content.contents.split(",");
  const imageOptions = {
    id: networkOptions.id,
    source: new Blob(),
    published: "false",
  };

  networkOptions.attached_media = [];

  // loop through images, upload them unpublished and attach to final post
  for (const image of images) {
    imageOptions.source = b64toBlob(image);
    const imageResp = await apiShare(imageOptions, "photos");

    if (!imageResp.success) {
      // clear out attached media so an error is returned
      networkOptions.attached_media.splice(
        0,
        networkOptions.attached_media.length
      );
      break;
    }

    networkOptions.attached_media.push({
      media_fbid: imageResp.data.data.id,
    });
  }

  if (networkOptions.attached_media.length <= 0) {
    throw new Error("Something went wrong uploading the images to facebook");
  }

  return networkOptions;
}

async function update(
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (
    networkOptions === undefined ||
    !networkOptions.id ||
    !networkOptions.facebook_page_post_id
  ) {
    return new Promise((resolve) =>
      resolve({ success: false, error: "Missing network data" })
    );
  }

  const apiResp = await apiEdit(networkOptions);
  return apiResp;
}

async function remove(
  networkOptions?: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (
    networkOptions === undefined ||
    !networkOptions.id ||
    !networkOptions.facebook_page_post_id
  ) {
    return new Promise((resolve) =>
      resolve({ success: false, error: "Missing network data" })
    );
  }

  const apiResp = await apiRemove(networkOptions);
  return apiResp;
}

function simpleShare(contentUrl: string): void {
  const url =
    "http://www.facebook.com/sharer/sharer.php?u=" +
    encodeURIComponent(contentUrl);

  window.open(url, "sharer", "toolbar=0,status=0,width=700,height=500");
}

async function apiShare(
  networkOptions: KcmNetworkOptions,
  endpoint = "feed"
): Promise<KcmSocialPromise> {
  if (!networkOptions.id) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error: "No id provided for Facebook API Share",
      })
    );
  }
  const pageTokenResponse = await getPageToken(networkOptions.id);
  if (pageTokenResponse.success && typeof pageTokenResponse.data === "string") {
    const postResponse = await postToPage(
      pageTokenResponse.data,
      networkOptions,
      endpoint
    );
    return postResponse;
  }
  return new Promise((resolve) => resolve(pageTokenResponse));
}

async function apiEdit(
  networkOptions: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (!networkOptions.id) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error: "No id provided for Facebook API Share",
      })
    );
  }
  const pageTokenResponse = await getPageToken(networkOptions.id);
  if (pageTokenResponse.success && typeof pageTokenResponse.data === "string") {
    const postResponse = await updatePagePost(
      pageTokenResponse.data,
      networkOptions
    );
    return postResponse;
  }
  return new Promise((resolve) => resolve(pageTokenResponse));
}

async function apiRemove(
  networkOptions: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  if (!networkOptions.id) {
    return new Promise((resolve) =>
      resolve({
        success: false,
        error: "No id provided for Facebook API Share",
      })
    );
  }
  const pageTokenResponse = await getPageToken(networkOptions.id);
  if (pageTokenResponse.success && typeof pageTokenResponse.data === "string") {
    const postResponse = await removePagePost(
      pageTokenResponse.data,
      networkOptions
    );
    return postResponse;
  }
  return new Promise((resolve) => resolve(pageTokenResponse));
}

async function getPageToken(pageId: string): Promise<KcmSocialPromise> {
  return new Promise((resolve) => {
    window.FB.api(
      "/" + pageId + "/?fields=access_token",
      "GET",
      {
        access_token: store.getters["auth/getServiceByField"](
          facebookService.name,
          "token"
        ),
      },
      function (response: { error?: string; access_token?: string }) {
        if (response.error) {
          checkTokenErrors(response);
          resolve({ success: false, error: response.error });
        }
        if (response.access_token) {
          resolve({ success: true, data: response.access_token });
        }
        resolve({
          success: false,
          error:
            "Facebook Get Page Token Failed - No Token returned from Facebook",
        });
      }
    );
  });
}

function postToPage(
  accessToken: string,
  networkOptions: KcmNetworkOptions,
  endpoint = "feed"
): Promise<KcmSocialPromise> {
  const postData: FacebookApiShareOptions = {
    access_token: accessToken,
  };

  postData.published = networkOptions.published ?? "true";
  if (networkOptions.schedule_time) {
    postData.scheduled_publish_time = Math.round(
      new Date(networkOptions.schedule_time).getTime() / 1000
    ).toString();
    postData.published = "false";
  }

  postData.link = networkOptions.link ?? undefined;
  postData.file_url = networkOptions.file_url ?? undefined;
  postData.url = networkOptions.url ?? undefined;
  postData.source = networkOptions.source ?? undefined;
  postData.title = networkOptions.title ?? undefined;
  postData.name = networkOptions.name ?? undefined;
  postData.message = networkOptions.message ?? undefined;
  postData.description = networkOptions.description ?? undefined;
  postData.thumb = networkOptions.thumb ?? undefined;
  postData.attached_media =
    JSON.stringify(networkOptions.attached_media) ?? undefined;
  // remove undefined fields
  Object.keys(postData).forEach((key: string) => {
    postData[key as keyof FacebookApiShareOptions] === undefined
      ? delete postData[key as keyof FacebookApiShareOptions]
      : {};
  });

  const formData = new FormData();
  Object.entries(postData).forEach((data) => {
    formData.append(data[0], data[1]);
  });

  return axios({
    method: "post",
    url: "https://graph.facebook.com/" + networkOptions.id + "/" + endpoint,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response: ServiceResponse) => {
      if (response.error) {
        checkTokenErrors(response);
      }

      return {
        success: true,
        data: response,
      };
    })
    .catch((response) => {
      return {
        success: false,
        error:
          "Something went wrong when sharing to Facebook: " +
          (response?.response?.data?.error?.message ?? "General Error"),
      };
    });
}

function updatePagePost(
  accessToken: string,
  networkOptions: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  const postData: FacebookApiShareOptions = {
    access_token: accessToken,
  };

  postData.published = networkOptions.published ?? "true";
  if (networkOptions.schedule_time) {
    postData.scheduled_publish_time = Math.round(
      new Date(networkOptions.schedule_time).getTime() / 1000
    ).toString();
    postData.published = "false";
  }

  postData.message = networkOptions.message ?? undefined;
  Object.keys(postData).forEach((key: string) => {
    postData[key as keyof FacebookApiShareOptions] === undefined
      ? delete postData[key as keyof FacebookApiShareOptions]
      : {};
  });

  const formData = new FormData();
  Object.entries(postData).forEach((data) => {
    formData.append(data[0], data[1]);
  });

  return axios({
    method: "post",
    url: "https://graph.facebook.com/" + networkOptions.facebook_page_post_id,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response: ServiceResponse) => {
      if (response.error) {
        checkTokenErrors(response);
      }

      return {
        success: true,
        data: response,
      };
    })
    .catch((response) => {
      return {
        success: false,
        error:
          "Something went wrong when updating the post on Facebook: " +
          response.message,
      };
    });
}

function removePagePost(
  accessToken: string,
  networkOptions: KcmNetworkOptions
): Promise<KcmSocialPromise> {
  const postData: FacebookApiShareOptions = {
    access_token: accessToken,
  };

  Object.keys(postData).forEach((key: string) => {
    postData[key as keyof FacebookApiShareOptions] === undefined
      ? delete postData[key as keyof FacebookApiShareOptions]
      : {};
  });

  const formData = new FormData();
  Object.entries(postData).forEach((data) => {
    formData.append(data[0], data[1]);
  });

  return axios({
    method: "delete",
    url: "https://graph.facebook.com/" + networkOptions.facebook_page_post_id,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  })
    .then((response: ServiceResponse) => {
      if (response.error) {
        checkTokenErrors(response);
      }

      return {
        success: true,
        data: response,
      };
    })
    .catch((response) => {
      return {
        success: false,
        error:
          "Something went wrong when deleting the post on Facebook: " +
          response.message,
      };
    });
}

async function getPages(): Promise<KcmSocialPromise> {
  const fields = ["name", "id"];
  return new Promise((resolve) => {
    window.FB.api(
      "/me/accounts?fields=" + fields.join(",") + "&limit=100",
      "GET",
      {
        access_token: store.getters["auth/getServiceByField"](
          facebookService.name,
          "token"
        ),
      },
      function (response: {
        data?: { name: string; id: string }[];
        error?: string;
      }) {
        if (response.error) {
          checkTokenErrors(response as ServiceResponse);
          resolve({ success: false, error: response.error });
        }
        if (response.data !== undefined) {
          const finalResponse: KcmSocialPageData[] = [];
          Object.values(response.data).forEach(
            (page: { name: string; id: string }): void => {
              finalResponse.push({ text: page.name, value: page.id });
            }
          );
          serviceData.pages = finalResponse;
          resolve({ success: true, data: finalResponse });
        }
      }
    );
  });
}

// returns the scheduled posts for a member's Meta business page
async function getScheduledPosts(pageId: string): Promise<{
  success: boolean;
  error?: string;
  data?: FacebookScheduledPost[];
}> {
  // must first get a page access token for the user's page
  return new Promise((resolve) => {
    window.FB.api(
      `/${pageId}?fields=access_token`,
      "GET",
      {
        access_token: store.getters["auth/getServiceByField"](
          facebookService.name,
          "token"
        ),
      },
      function (response: {
        access_token?: string;
        id?: string;
        error?: string;
      }) {
        if (response.error) {
          resolve({ success: false, error: response.error });
          return;
        }
        const fields = [
          "id",
          "attachments{description,media_type,unshimmed_url,url,title}",
          "full_picture",
          "message",
          "picture",
          "scheduled_publish_time",
          "permalink_url",
        ];
        window.FB.api(
          `/${pageId}/scheduled_posts?fields=${fields.join(",")}&limit=100`,
          "GET",
          {
            access_token: response.access_token,
          },
          function (response: {
            data?: FacebookScheduledPost[];
            paging?: { cursors: { before: string; after: string } };
            error?: string;
          }) {
            if (response.error) {
              resolve({ success: false, error: response.error });
              return;
            } else {
              resolve({ success: true, data: response.data });
            }
          }
        );
      }
    );
  });
}
