import store from "@/state/store";
import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { KCMHelperUtils } from "@/composables/helperUtils";
import { helpers } from "@/services/helpers";
import basicRoutes from "@/router/routes/basicPages";
import { profileRoutes } from "@/router/routes/profile";
import resourcesRoutes from "@/router/routes/resources";
import { marketUpdateRoutes } from "@/router/routes/marketUpdates";
import { accountRoutes } from "@/router/routes/account";
import { contentRoutes } from "@/router/routes/shareableContent";
import scriptBuilderRoutes from "@/router/routes/scriptBuilder";
import { learningCenterRoutes } from "@/router/routes/learningCenter";
import { hasPerk, getAllPerks } from "@/router/views/memberPerks/perksData";
import realtalkRoutes from "@/router/routes/realtalk";

const appStorePath =
  "itms-apps://apps.apple.com/us/app/keeping-current-matters-mobile/id1600144783";
const playStorePath =
  "https://play.google.com/store/apps/details?id=com.keepingcurrentmatters.mobile";

const routes1: RouteConfig[] = [
  // DEV TEST ROUTE, only available if NODE_ENV is not "production"
  {
    path: "/dev-testing",
    name: "Dev Testing",
    beforeEnter: async (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      if (process.env.NODE_ENV === "production") {
        return next("/dashboard");
      }
      next();
    },
    component: () => import("@/router/views/dev-testing.vue"),
  },

  ...profileRoutes["1"],
  ...accountRoutes["1"],
  ...contentRoutes["1"],
  ...learningCenterRoutes["1"],
  ...basicRoutes,
  ...resourcesRoutes,
  ...marketUpdateRoutes["1"],
  ...scriptBuilderRoutes,
  ...realtalkRoutes,

  {
    path: "/winning-expired-listings",
    name: "3 Steps to Winning Expired Listings",
    meta: {
      authRequired: true,
    },
    component: () => import("@/router/views/winning-expired-listings.vue"),
  },
  {
    path: "/social-accounts/scheduled-facebook-posts",
    name: "Scheduled Facebook Posts",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/settings/scheduled-facebook-posts.vue"),
  },
  {
    path: "/automatic-social-media-posting",
    name: "Automatic Social Media Posting",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/settings/automatic-social-media-posting.vue"),
  },
  {
    path: "/success-plan",
    name: "Onboarding",
    meta: { authRequired: true },
    component: () => import("@/router/views/onboarding/onboarding-page.vue"),
  },
  {
    path: "/success-plan-complete",
    name: "Success Plan",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/onboarding/onboarding-complete-page.vue"),
  },
  {
    path: "/email-builder",
    name: "Email Builder",
    meta: { authRequired: true, featureRequired: "email-builder" },
    component: () =>
      import("@/router/views/personalizedContent/email-builder-page.vue"),
  },
  { path: "/email", redirect: "/email-builder" },
  { path: "/newsletter", redirect: "/email-builder" },

  {
    path: "/2024-product-roundup",
    name: "2024 Product Roundup",
    meta: { authRequired: false },
    component: () =>
      import("@/router/views/promotionals/kcm-product-highlights-2023.vue"),
  },
  {
    path: "/product-suggestion-box",
    name: "Product Suggestion Box",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/productSuggestionBox/product-suggestion-box.vue"),
  },
  {
    path: "/app",
    beforeEnter: (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): void => {
      const helper = new KCMHelperUtils();
      if (helper.isMobile()) {
        // determine android or apple
        const userAgent = navigator.userAgent || navigator.vendor;
        if (/android/i.test(userAgent)) {
          window.location.href = playStorePath;
        } else if (/iPad|iPhone|iPod/.test(userAgent)) {
          window.location.href = appStorePath;
        } else {
          next("/realtalk");
        }
      } else {
        next("/realtalk");
      }
    },
  },
  {
    path: "/local",
    name: "Your Local Market",
    meta: { authRequired: true },
    component: () => import("@/router/views/local/local-page.vue"),
  },
  { path: "/local-promo", redirect: "/local" },
  {
    path: "/video-masterclass",
    name: "Video Masterclass for Real Estate Agents",
    meta: { authRequired: true },
    component: () => import("@/router/views/realtalk/members-masterclass.vue"),
  },
  {
    path: "/insider-training-on-video",
    redirect: { path: "/video-masterclass" },
  },
  {
    path: "/graphic-builder",
    name: "Graphic Builder",
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/graphicBuilder/graphic-builder.vue"),
    beforeEnter: async (
      _to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      next();
    },
  },
  {
    path: "/support",
    beforeEnter: (): void => {
      window.location.replace("https://help.mykcm.com");
    },
  },
  {
    path: "/my-perks",
    name: "My Perks",
    alias: ["/partners", "/member-perks"],
    props: true,
    meta: { authRequired: true },
    component: () => import("@/router/views/memberPerks/member-perks.vue"),
  },
  {
    path: "/my-perks/:perk",
    name: "My Perk Details",
    props: true,
    meta: { authRequired: true },
    component: () =>
      import("@/router/views/memberPerks/member-perk-detail.vue"),
    beforeEnter: async (
      to: Route,
      _from: Route,
      next: NavigationGuardNext
    ): Promise<void> => {
      const isStandard = store.getters["auth/isAllowedReferrals"];
      let perk = hasPerk(to.params.perk);

      if (!perk) {
        try {
          perk = await getAllPerks(true, 0, [], to.params.perk);
        } catch (err) {
          helpers.customSentryError(
            "Getting all perk data",
            "Failed to recursively get all perk data",
            { error: err }
          );

          return next("/my-perks");
        }
      }

      if (!perk || (perk.standardOnly && !isStandard)) {
        next("/my-perks");
      }

      next();
    },
  },
  { path: "/partners/redx", redirect: "/my-perks/redx" },
  {
    path: "/partnerships/verification.php",
    beforeEnter: (to: Route): void => {
      window.location.replace(
        process.env.VUE_APP_AUTH_API_ROOT +
          "/industries/" +
          process.env.VUE_APP_INDUSTRY_ID +
          "/services/bombbomb/validate/?pik=" +
          to.query.pik
      );
    },
  },
  {
    path: "/new-walkthrough",
    name: "UX Revamp Walkthrough",
    meta: { authRequired: true },
    component: () => import("@/router/views/trainingCenter/ux-revamp.vue"),
  },
];

export default routes1;
